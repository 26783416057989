module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-37305501-3"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":true,"exclude":[]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"codeselfstudy","short_name":"codeselfstudy","start_url":"/","background_color":"#666666","theme_color":"#666666","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"56fd8871c5301a7f5bb167759b1ffb88"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
